import ControlledNumberField from '@/components/ControlledNumberField';
import { DialogTitle } from '@/components/Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Dialog, Grid, Tab, Tabs } from '@mui/material';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { CHANGES_SAVED_MESSAGE } from '@/common/messages';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { useEffect, useState } from 'react';
import { detailedViewColumns, sortableLotInfoBlocks } from '@/components/CallFeedTab/constants';
import ControlledDraggableList from '@/components/DraggableList/ControlledDraggableList';
import useLocalStorage from '@/components/hooks/useLocalStorage';

interface CallFeedSettingsModalProps {
  defaultTabIndex?: number;
  onSuccess?: () => void;
}

export type FormFields = {
  settingCallFeedDmiPerHeadIncrement: number;
  settingCallFeedAfPerHeadIncrement: number;
  settingCallFeedAfIncrement: number;
  lotInfoBlocksOrder: string[];
  detailedViewColumnsOrder: string[];
};

export const showCallFeedSettingsModal = (props: CallFeedSettingsModalProps) =>
  ModalManager.show(CallFeedSettingsModal, props);

const CallFeedSettingsModal = ModalManager.create<CallFeedSettingsModalProps>(({ onSuccess, defaultTabIndex }) => {
  const modal = useModal();
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex ?? 0);
  const [{ settings, updating: isUpdating }, updateSettings] = useOrgSettings();

  const { handleSubmit, setValue, control } = useForm<FormFields>({
    defaultValues: {
      settingCallFeedDmiPerHeadIncrement: settings['feeding.dmiPerHeadIncrement'],
      settingCallFeedAfPerHeadIncrement: settings['feeding.afPerHeadIncrement'],
      settingCallFeedAfIncrement: settings['feeding.afIncrement'],
      lotInfoBlocksOrder: [],
    },
  });

  const onSubmit = async (data: FormFields) => {
    setLotInfoBlocksOrderLS(data.lotInfoBlocksOrder);
    setDetailedViewColumnsOrderLS(data.detailedViewColumnsOrder);

    await updateSettings({
      merge: {
        'feeding.dmiPerHeadIncrement': data.settingCallFeedDmiPerHeadIncrement,
        'feeding.afPerHeadIncrement': data.settingCallFeedAfPerHeadIncrement,
        'feeding.afIncrement': data.settingCallFeedAfIncrement,
      },
    });

    enqueueSnackbar(CHANGES_SAVED_MESSAGE, { variant: 'success' });

    onSuccess?.();
    modal.remove();
  };

  const [lotInfoBlocksOrderLS, setLotInfoBlocksOrderLS] = useLocalStorage<string[]>('lotInfoBlocksOrder', []);
  const [detailedViewColumnsOrderLS, setDetailedViewColumnsOrderLS] = useLocalStorage<string[]>(
    'detailedViewColumnsOrder',
    []
  );
  useEffect(() => {
    if (lotInfoBlocksOrderLS.length) {
      setValue('lotInfoBlocksOrder', lotInfoBlocksOrderLS);
    }

    if (detailedViewColumnsOrderLS.length) {
      setValue('detailedViewColumnsOrder', detailedViewColumnsOrderLS);
    }
  }, [lotInfoBlocksOrderLS, detailedViewColumnsOrderLS, setValue]);

  return (
    <Dialog onClose={modal.remove} open={modal.visible} maxWidth="xs">
      <DialogTitle
        title="Feed Call Settings"
        containerProps={{ sx: { px: 4, pt: 4 }, mb: 1 }}
        onCloseClick={() => modal.remove()}
      />
      <Box px={4}>
        <Tabs value={activeTabIndex} onChange={(e, newValue) => setActiveTabIndex(newValue)} sx={{ mb: 3 }}>
          <Tab label="General" />
          <Tab label="Detailed View" />
          <Tab label="Table" />
        </Tabs>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box minHeight={300}>
            {activeTabIndex === 0 && (
              <>
                <Alert severity="info" icon={false}>
                  The following settings will adjust the increments for the (+) and (-) buttons used on the Call Feed
                  Screens
                </Alert>
                <Box sx={{ py: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} display="flex" alignItems="center">
                      DMI /HD - Adjust Size (lbs)
                    </Grid>
                    <Grid item xs={6}>
                      <ControlledNumberField
                        name="settingCallFeedDmiPerHeadIncrement"
                        decimalPlaces={2}
                        control={control}
                        rules={{ min: { value: 0, message: 'Must be greater than 0' } }}
                      />
                    </Grid>

                    <Grid item xs={6} display="flex" alignItems="center">
                      AF /HD - Adjust Size (lbs)
                    </Grid>
                    <Grid item xs={6}>
                      <ControlledNumberField
                        name="settingCallFeedAfPerHeadIncrement"
                        decimalPlaces={2}
                        control={control}
                        rules={{ min: { value: 0, message: 'Must be greater than 0' } }}
                      />
                    </Grid>

                    <Grid item xs={6} display="flex" alignItems="center">
                      As Fed - Adjust Size (lbs)
                    </Grid>
                    <Grid item xs={6}>
                      <ControlledNumberField
                        name="settingCallFeedAfIncrement"
                        decimalPlaces={2}
                        control={control}
                        rules={{ min: { value: 0, message: 'Must be greater than 0' } }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            {activeTabIndex === 1 && (
              <Box sx={{ width: '360px' }}>
                <ControlledDraggableList
                  control={control}
                  name="lotInfoBlocksOrder"
                  items={sortableLotInfoBlocks.map((block, index) => ({
                    value: block.label,
                    label: block.label,
                  }))}
                />
              </Box>
            )}
            {activeTabIndex === 2 && (
              <Box sx={{ width: '360px' }}>
                <ControlledDraggableList
                  control={control}
                  name="detailedViewColumnsOrder"
                  items={detailedViewColumns.map((column) => ({
                    value: column,
                    label: column,
                    isDragDisabled: column === 'Date',
                  }))}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2, pb: 4 }}>
            <Button variant="text" type="reset" onClick={() => modal.remove()}>
              Cancel
            </Button>
            <LoadingButton loading={isUpdating} type="submit">
              Save
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
});
