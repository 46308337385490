import '@/components/registerChartjs';
import { GetPenDropDetailsDialogQuery, PenDropAtDate } from '@/web-types';
import { DefaultDataPoint } from 'chart.js';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartProps } from 'react-chartjs-2/dist/types';
import { formatNumber } from '../helpers/format';

type BarChartProps = ChartProps<'bar', DefaultDataPoint<'bar'>, unknown>;
type BarChartOptions = BarChartProps['options'];
type BarChartData = BarChartProps['data'];
type RationMap = Map<number, GetPenDropDetailsDialogQuery['getPenDropDetails']['rations'][0]>;

interface PenHistoryGraphProps {
  penDropAtDates: PenDropAtDate[];
  rationMap: RationMap;
  showDmi: boolean;
}

const DATASETS = {
  HISTORICAL_MAX: { label: 'Historical Max', backgroundColor: '#FBD34D', borderColor: '#FBD34D' },
  CALLED: { label: 'Feed Call', backgroundColor: '#5842BD', borderColor: '#5842BD' },
  FED_PER_HEAD: { label: 'As Fed / HD', backgroundColor: '#62AAF1', borerColor: '' },
  DMI_PER_HEAD: { label: 'DMI / HD', backgroundColor: '#62AAF1', borerColor: '' },
};

export const PenHistoryGraph = ({ penDropAtDates, rationMap, showDmi }: PenHistoryGraphProps) => {
  const options = useMemo<BarChartOptions>(
    () =>
      ({
        layout: { padding: { top: 30 } },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            titleAlign: 'center',
            bodyAlign: 'right',
            callbacks: {
              label: (context) => `${context.dataset.label || ''}: ${formatNumber(context.parsed.y)}`,
              footer: (context) => {
                let result = [];
                const penDropAtDate = penDropAtDates[context[0].parsed.x];
                if (penDropAtDate.numDeads > 0) result.push(`Dead: ${formatNumber(penDropAtDate.numDeads)}`);
                if (penDropAtDate.numMoves > 0) result.push(`Moves: ${formatNumber(penDropAtDate.numMoves)}`);
                if (penDropAtDate.numAdds > 0) result.push(`Adds: ${formatNumber(penDropAtDate.numAdds)}`);
                if (penDropAtDate.numSales > 0) result.push(`Sales: ${formatNumber(penDropAtDate.numSales)}`);
                if (penDropAtDate.numTreated > 0) result.push(`Treated: ${formatNumber(penDropAtDate.numTreated)}`);
                return result.join(', ');
              },
            },
          },
          annotation: getAnnotations(penDropAtDates),
        },
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
      }) as BarChartOptions,
    [penDropAtDates]
  );
  const chartData = useMemo(() => getChartData(penDropAtDates, rationMap, showDmi), [penDropAtDates, showDmi]);
  return <Bar options={options} data={chartData} />;
};

const getChartData = (penDropAtDates: PenDropAtDate[], rationMap: RationMap, showDmi: boolean) => {
  const rationDatasets = getRationDatasets(penDropAtDates, rationMap, showDmi);
  return {
    labels: penDropAtDates.map((item) => dayjs(item.date).format('MM/DD')),
    datasets: [
      {
        pointRadius: 0,
        type: 'line',
        label: DATASETS.HISTORICAL_MAX.label,
        backgroundColor: DATASETS.HISTORICAL_MAX.backgroundColor,
        borderColor: DATASETS.HISTORICAL_MAX.borderColor,
        borderWidth: 2,
        fill: false,
        data: penDropAtDates.map((item) =>
          formatNumber(showDmi ? item.historicalMaxFedPerHead.dryMatterLbs : item.historicalMaxFedPerHead.asFedLbs)
        ),
        borderDash: [5, 5],
        stack: 'historicalMax',
      },
      {
        type: 'line',
        label: DATASETS.CALLED.label,
        backgroundColor: DATASETS.CALLED.backgroundColor,
        borderColor: DATASETS.CALLED.borderColor,
        borderWidth: 2,
        fill: false,
        data: penDropAtDates.map((item) =>
          formatNumber(showDmi ? item.calledPerHead.dryMatterLbs : item.calledPerHead.asFedLbs)
        ),
        borderDash: [0, 6],
        stack: 'calledLbs',
      },
      ...rationDatasets,
    ],
  } as BarChartData;
};

export const getRationDatasets = (penDropAtDates: PenDropAtDate[], rationMap: RationMap, showDmi: boolean) => {
  const allRationIds = [
    ...new Set(penDropAtDates.map((day) => day.rationFedPerHead.map((rations) => rations.rationId)).flat()),
  ];

  return allRationIds.map((rationId) => {
    const ration = rationMap.get(rationId);
    return {
      label: ration?.name ?? 'Unknown ration',
      backgroundColor: ration?.color ?? DATASETS.FED_PER_HEAD.backgroundColor,
      data: penDropAtDates.map((dayData, dayIdx) => {
        const rationFedPerHead = dayData.rationFedPerHead.find((r) => r.rationId === rationId)?.fedPerHead;
        const val = showDmi ? rationFedPerHead?.dryMatterLbs ?? 0 : rationFedPerHead?.asFedLbs ?? 0;
        return val;
      }),
    };
  });
};

function getAnnotations(penDropAtDates: PenDropAtDate[]) {
  const annotations = {} as any;
  penDropAtDates.forEach((penDropAtDate, index) => {
    const deads = penDropAtDate.numDeads;
    const moves = penDropAtDate.numMoves + penDropAtDate.numAdds + penDropAtDate.numSales;
    const treatments = penDropAtDate.numTreated;
    const total = deads + moves + treatments;
    if (total === 0) return;

    annotations[penDropAtDate.date] = {
      type: 'line',
      borderColor: '#b9bbbf',
      borderDash: [6, 6],
      borderWidth: 1,
      label: {
        display: true,
        content: () => {
          const img = new Image();
          img.width = 24;
          img.height = 24;

          const icons = [
            { condition: deads > 0, src: '/assets/images/icons/close.svg' },
            { condition: moves > 0, src: '/assets/images/icons/swap-horiz.svg' },
            { condition: treatments > 0, src: '/assets/images/icons/medical-services.svg' },
          ];

          const activeIcons = icons.filter((icon) => icon.condition);

          img.src = activeIcons.length > 1 ? '/assets/images/icons/layers.svg' : activeIcons[0]?.src || '';
          return img;
        },
        backgroundColor: 'rgba(0,0,0,0)',
        position: 'start',
        yAdjust: -40,
      },
      scaleID: 'x',
      value: index,
      beforeDraw: (context: any) => {
        const ctx = context.chart.ctx;
        const { x, y, options } = context.element;
        ctx.save();
        ctx.lineWidth = options.borderWidth;
        ctx.strokeStyle = '#b9bbbf';
        ctx.setLineDash([6, 6]);
        ctx.lineDashOffset = options.borderDashOffset;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x, 20);
        ctx.stroke();
        ctx.restore();
        return true;
      },
    };
  });

  return {
    clip: false,
    annotations,
  };
}
