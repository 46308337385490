import React, { useState } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import List from '@mui/material/List';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import Box from '@mui/material/Box';
import { isPresent } from 'ts-is-present';

interface DraggableItem {
  value: string;
  label: string;
  isDragDisabled?: boolean;
}

export interface DraggableListProps {
  items: DraggableItem[];
  defaultValue?: string[];
  onChange?: (values: string[]) => void;
}

const sortItemsUsingDefaultValue = (items: DraggableItem[], defaultValue: string[] = []) => {
  const sortedItems = defaultValue.map((value) => items.find((item) => item.value === value)).filter(isPresent);
  const remainingItems = items.filter((item) => !defaultValue.includes(item.value));
  return [...sortedItems, ...remainingItems];
};

// a little function to help us with reordering the result
const reorder = (list: DraggableItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const DraggableList = ({ items, defaultValue, onChange }: DraggableListProps) => {
  const [orderedItems, setOrderedItems] = useState(sortItemsUsingDefaultValue(items, defaultValue));

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedItems = reorder(orderedItems, result.source.index, result.destination.index);
    setOrderedItems(reorderedItems);
    onChange?.(reorderedItems.map((item) => item.value));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List ref={provided.innerRef}>
            {orderedItems.map((item, index) => (
              <Draggable key={item.value} draggableId={item.value} index={index} isDragDisabled={item.isDragDisabled}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    display="flex"
                    alignItems="center"
                    gap={1}
                    p={0.2}
                    borderRadius={2}
                    my={0.5}
                    sx={{
                      fontSize: 11,
                      cursor: item.isDragDisabled ? 'not-allowed' : 'grab',
                      userSelect: 'none',
                      background: (theme) => (snapshot.isDragging ? theme.palette.blue[50] : theme.palette.grey[50]),
                      transition: '1s background',
                    }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicatorIcon color={item.isDragDisabled ? 'disabled' : 'secondary'} />
                    {item.label}
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
